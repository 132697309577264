<template>
  <div class="main-wrap">
    <router-view />
    <van-dialog
      v-model:show="refreshTokenIsExpired"
      class="token_expire_alert"
      confirm-button-text="重新登陆"
      @confirm="reLogin"
    >
      <p
        :style="{
          'text-align': 'center',
          padding: '20px',
          'font-size': '14px',
          'line-height': '24px',
        }"
      >
        你已长时间没有操作，请重新登录！
      </p>
    </van-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getUrlCode } from "@/utils/utils";
import * as api from "@/api";
export default {
  data() {
    return {
      refreshTokenIsExpired: false,
    };
  },
  computed: {
    ...mapGetters(["botCode", "authToken", "botInfo"]),
  },
  created() {
    let botCode = getUrlCode().bot;
    if (botCode) {
      // if (!botCode) {
      //   this.$router.push({
      //     name: "nofound",
      //   });
      // }
      if (botCode != this.botCode) {
        this.setBotCode(botCode);
        this.setCurrentUser(null);
        this.setToken(null);
        window.localStorage.removeItem("currentSessionId");
      }
      api
        .getBotInfo()
        .then((res) => {
          if (res.libraryName) {
            document.title = res.libraryName;
          }
          this.setBotInfo(res);
        })
        .catch((err) => {
          this.$router.push({
            name: "nofound",
          });
        });
      this.showCurrentTime();
    }
  },
  methods: {
    ...mapActions(["setBotCode", "setCurrentUser", "setToken", "setBotInfo"]),
    showCurrentTime() {
      let now = new Date();
      if (
        this.authToken &&
        now.valueOf() >= this.authToken.refresh_token_expires
      ) {
        this.refreshTokenIsExpired = true;
      } else {
        setTimeout(() => {
          this.showCurrentTime();
        }, 1000);
      }
    },
    reLogin() {
      this.setCurrentUser(null);
      this.setToken(null);
      this.$router.push({
        name: "Author",
      });
    },
  },
};
</script>

<style lang="less">
@import "~@/styles/common.less";
</style>
<style lang="less" scoped>
.main-wrap {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  & :deep(.van-dialog.token_expire_alert) {
    width: initial;
  }
  & :deep(.van-button__text) {
    font-size: 16px;
  }
}
@media screen and (min-width: 680px) {
  .main-wrap {
    & :deep(.van-dialog.token_expire_alert) {
      font-size: 16px;
      width: 20em;
      border-radius: 16px;
    }
    & :deep(.van-button) {
      font-size: 16px;
      height: 3em;
      line-height: 3em;
      padding: 0 0.5em;
    }
    & :deep(.van-button__text) {
      font-size: 16px;
    }
  }
}
</style>
