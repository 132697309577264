<template>
  <div class="main-container" v-if="botInfo">
    <div class="header">
      <div class="logo"><img :src="botInfo.avatar" /></div>
      <div class="title">
        <!-- <h1>书音智能对话平台</h1>
        <h2>为图书馆打造的专业智能助手</h2> -->
        <h1>Hi，我是{{ botInfo.botName }}</h1>
        <h2 v-if="botInfo.libraryName">{{ botInfo.libraryName }}智能助手</h2>
        <h2 v-else>图书馆专业智能助手</h2>
      </div>
    </div>
    <template v-if="!isLogining">
      <div v-if="isWeiXin" class="btn-wrap">
        <van-button
          round
          block
          icon="wechat"
          size="large"
          type="primary"
          @click="redirectToWx"
          >微信授权登录后开启对话</van-button
        >
      </div>
      <wxlogin
        v-else
        :appid="open_appId"
        :scope="'snsapi_login'"
        :theme="'black'"
        :redirect_uri="web_author_callback"
        :href="bast64css"
        :self_redirect="true"
        rel="external nofollow"
        class="wechat-qrcode"
      >
      </wxlogin>
    </template>
    <van-loading size="24px" vertical v-else>登录中...</van-loading>
    <van-dialog
      v-model:show="cellphoneFormShow"
      title="绑定手机号"
      class="cellphone-form"
      show-cancel-button
      confirmButtonText="确认提交"
      cancelButtonText="下次再说"
      :beforeClose="closeDialog"
    >
      <van-form ref="ruleForm" :model="formData" validate-first>
        <van-field
          name="cellphone"
          center
          v-model="formData.cellphone"
          type="tel"
          required
          left-icon="phone"
          placeholder="请输入手机号"
          label-width="4em"
          class="form-vield"
          :rules="[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入正确的手机号',
            },
          ]"
        >
        </van-field>

        <van-field
          type="digit"
          center
          class="form-vield"
          placeholder="请输入短信验证码"
          required
          left-icon="coupon"
          v-model="formData.veriCode"
          maxlength="6"
          :rules="[
            {
              required: true,
              message: '请输入短信验证码',
            },
          ]"
        >
          <template #button>
            <van-button
              @click="sendSMS"
              class="primary-btn"
              :disabled="seconds > 0"
              native-type="button"
              size="small"
            >
              <span v-if="seconds <= 0">发送验证码</span>
              <span v-else>{{ seconds }}秒后重发</span>
            </van-button>
          </template></van-field
        >
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getUrlCode, isWeiXin } from "@/utils/utils";
import wxlogin from "vue-wxlogin";
import config from "../config/config";
import { showToast } from "vant";
import * as api from "@/api";
export default {
  name: "author",
  components: {
    wxlogin,
  },
  data() {
    return {
      isWeiXin: true,
      redirect_uri: "",
      web_author_callback: "",
      bast64css:
        "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O2hlaWdodDoyMDBweH0NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHh9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lfQ0KaWZyYW1lIHtoZWlnaHQ6IDMyMnB4O30NCg==",
      isLogining: false,
      mp_appId: "",
      open_appId: "",
      cellphoneFormShow: false,
      formData: {
        cellphone: "",
        veriCode: "",
      },
      seconds: 0,
    };
  },
  computed: {
    ...mapGetters(["botInfo", "beforeLogin", "botCode"]),
  },
  mounted() {
    if (!this.isWeiXin) {
      window.addEventListener("message", this.receiveMessage);
    }
  },
  created() {
    console.log("author beforelogin", this.beforeLogin);
    this.mp_appId = config.mp_appId;
    this.open_appId = config.open_appId;
    this.isWeiXin = isWeiXin();
    let code = getUrlCode().code || ""; // 截取code
    let local = window.location.href; // 获取页面url
    this.redirect_uri = encodeURIComponent(local);
    this.web_author_callback = encodeURIComponent(
      `${window.location.protocol}//${window.location.host}/#/author_callback`
    );
    console.log(this.web_author_callback);
    if (code != "") {
      window.sessionStorage.weixin_code = code;
      let _local = local.replace(/(((code|state)=[^#]*)?&*)/g, "");
      window.location.replace(_local);
    } else {
      let weixin_code = window.sessionStorage.weixin_code || "";
      if (weixin_code != "") {
        this.isLogining = true;
      }
      if (this.isLogining) {
        window.sessionStorage.removeItem("weixin_code");
        this.login(weixin_code);
        // api
        //   .loginByWechat(weixin_code, this.botCode, this.isWeiXin ? 0 : 1)
        //   .then((res) => {
        //     this.setCurrentUser(res.userInfo);
        //     this.setToken(res.token);
        //     if (res.userInfo.cellphone) {
        //       this.goBeforeLoginUrl();
        //     } else {
        //       this.cellphoneFormShow = true;
        //     }
        //   });
      }
    }
  },
  methods: {
    ...mapActions(["setCurrentUser", "setToken", "setBeforeLogin"]),
    receiveMessage(event) {
      let code = event.data.code;
      if (code) {
        this.login(code);
      }
    },
    login(code) {
      api
        .loginByWechat(code, this.botCode, this.isWeiXin ? 0 : 1)
        .then((res) => {
          this.setCurrentUser(res.userInfo);
          this.setToken(res.token);
          if (res.userInfo.cellphone) {
            this.goBeforeLoginUrl();
          } else {
            this.cellphoneFormShow = true;
          }
        });
    },
    redirectToWx: function () {
      let _this = this;
      window.location.replace(
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          _this.mp_appId +
          "&redirect_uri=" +
          _this.redirect_uri +
          "&response_type=code&scope=" +
          (this.botInfo.ifGetBaseInfoFromWx
            ? "snsapi_userinfo"
            : "snsapi_base") +
          "&state=#wechat_redirect"
      );
    },
    goBeforeLoginUrl() {
      if (!this.beforeLogin || this.beforeLogin.path.indexOf("/author") != -1) {
        this.$router.replace("/");
      } else {
        this.$router.replace({
          path: this.beforeLogin.path,
          query: this.beforeLogin.query,
        });
      }
      this.setBeforeLogin(null);
    },
    sendSMS() {
      this.formData.veriCode = "";
      this.$refs.ruleForm.validate("cellphone").then(() => {
        let regExp;
        regExp = new RegExp(/^1[3|4|5|6|7|8|9]\d{9}$/);
        if (!regExp.test(this.formData.cellphone)) {
          showToast({
            message: "手机号格式有误",
            className: "custom-toast-text",
          });
          return false;
        }
        api.sendSmsCaptcha(this.formData.cellphone).then((res) => {
          this.seconds = 120;
          this.countDown();
        });
      });
    },
    closeDialog(action) {
      let that = this;
      new Promise((resolve) => {
        if (action == "cancel") {
          that.goBeforeLoginUrl();
          resolve(true);
        } else {
          that.$refs["ruleForm"].validate().then(() => {
            that.submit().then(() => {
              resolve(true);
            });
          });
        }
      });
    },
    submit() {
      let that = this;
      return this.$refs["ruleForm"].validate().then(() => {
        return api
          .updateCellphone(this.formData.cellphone, this.formData.veriCode)
          .then(() => {
            showToast({
              message: "手机号绑定成功！",
              className: "custom-toast-text",
            });
            that.goBeforeLoginUrl();
          });
      });
    },

    countDown() {
      let that = this;
      let seconds = that.seconds;
      if (seconds > 0) {
        setTimeout(() => {
          that.seconds = seconds - 1;
          that.countDown();
        }, 1000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  padding: 20px 0 40px;
  background: #1989fa;
  margin-bottom: 60px;
  .logo {
    width: 120px;
    margin: 20px auto 0;
    animation-name: jump;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    border-radius: 100%;
    overflow: hidden;
    & img {
      width: 120px;
      display: block;
    }
  }
  .title {
    text-align: center;
    & h1 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 8px;
      color: #fff;
    }
    & h2 {
      font-size: 18px;
      color: #ccc;
      font-weight: normal;
    }
  }
}
.btn-wrap {
  padding: 20px 40px;
}
.wechat-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.van-form {
  padding: 0 10px;
}
@media screen and (min-width: 680px) {
  .header {
    padding: 20px 0;
    background: #1989fa;
    margin-bottom: 20px;
    border-radius: 0 0 50% 50%;
    .logo {
      width: 200px;
      margin: 20px auto 0;
      animation-name: jump;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      border-radius: 100%;
      overflow: hidden;
      & img {
        width: 200px;
        display: block;
      }
    }
    .title {
      margin-bottom: 20px;
      & h1 {
        font-size: 24px;
        margin-bottom: 8px;
        color: #fff;
      }
      & h2 {
        font-size: 18px;
        color: #ccc;
      }
    }
    .wechat-qrcode {
      width: 300px;
      margin: 0 auto;
    }
  }
  .main-container {
    & :deep(.van-dialog.cellphone-form) {
      width: 400px;
      font-size: 16px;
      border-radius: 16px;
    }
    & :deep(.van-dialog__header) {
      font-size: 18px;
      padding-top: 24px;
      padding-bottom: 12px;
      line-height: 1.5em;
    }
  }
  .van-form {
    padding: 0 10px;
    & .van-cell {
      padding: 20px 20px;
      font-size: 16px;
      line-height: 1.5em;
      &:after {
        right: 20px;
        left: 20px;
      }
      & .van-button {
        line-height: 2em;
        height: 2em;
      }
      & :deep(.van-field__left-icon .van-icon) {
        font-size: 18px;
      }
    }
    & :deep(.van-field__error-message) {
      font-size: 14px;
    }
  }
}
@keyframes jump {
  to {
    transform: translateY(-40px);
  }
}
</style>

