import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MessageBoard from '../views/MessageBoard.vue'
import MessageBoardDetail from '../views/MessageboardDetail.vue'
import NotFound from '@/views/404'
import Author from '@/views/Author.vue'
import AuthorCallback from '../views/AuthorCallback.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '书音智能对话平台'
    }
  },{
    path: '/messageboard',
    name: 'MessageBoard',
    component: MessageBoard,
    meta:{
      title: '人工留言'
    }
  },
  {
    path: '/messageboard_detail',
    name: 'MessageBoardDetail',
    component: MessageBoardDetail,
    meta:{
      title: '留言详情'
    }
  },
  {
    path: '/author',
    name: 'Author',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Author.vue'),
    component: Author,
    meta: {
      title: '书音智能对话平台'
    }
  },{
    path: '/author_callback',
    name: 'AuthorCallback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Author.vue'),
    component: AuthorCallback,
    meta: {
      title: '书音智能对话平台'
    }
  },
  {
    path: '/404',
    component: NotFound,
    name: 'nofound',
    meta: {
      title: '页面不存在'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { path: '/404' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
