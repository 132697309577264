<template>
  <div class="loading">登录中...</div>
</template>
<script>
import { getUrlCode } from "@/utils/utils";
export default {
  name: "AuthorCallback",
  data() {
    return {};
  },
  created() {
    let code = this.$route.query.code;
    window.parent.postMessage({ code }, "*");
  },
};
</script>
<style lang="less" scoped>
.loading{
  text-align: center;
  padding: 80px 0;
}
</style>
